<template>
  <div id="theories-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead mb-5">
      {{ activeRoute.description }}
    </p>

    <div v-if="!theories.length || showExamples">
      <button v-if="showExamples" @click="showExamples = false" class="btn btn-primary mb-5">Zurück zu deinen Theorien</button>

      <div class="mb-5">
        <div class="alert alert-primary mb-5">
          <h3>So funktioniert es:</h3>
          <p class="mb-5">
            Eine Theorie besteht aus einer beliebigen Anzahl Hypothesen, die auf die Theorien "einzahlen". Eine Theorie ist also eine Art Grundsatz, wie sich
            Nutzer verhalten, der überprüft werden muss. Überprüft werden die Hypothesen durch sogenannte Experimente. Kannst du eine Theorie mit genug
            Experimenten bestätigen, dann kannst du dir ziemlich sicher sein, dass weitere Experimente, die der Theorie folgen, dir und deiner Website weiteren
            Wert bescheren!
          </p>

          <h3>Was heißt das in der Praxis?</h3>
          <p>
            Wir haben dir bereits ein paar validierte Hypothesen aus der Wissenschaft mitgebracht, an denen du es ausprobieren kannst. <br>
            Such' dir einfach eine aus, die für dich interessant klingt. Später kannst du auch selbst ganz einfach Theorien erstellen und komplexere Grundsätze
            validieren.
          </p>
        </div>

        <h3 v-if="templateTheories.length">Unsere Vorschläge für deine erste Theorie:</h3>
        <div class="row mt-3">
          <div v-for="(theory, index) in templateTheories" class="col-sm-6 col-md-4 mb-3" :key="index">
            <div class="card h-100">
              <div class="card-header">
                <h4 class="mb-0">
                  <i class="fad" :class="theory.icon"></i> {{ theory.title }}
                  <span v-if="activeTheoryId === theory.id" class="text-muted">(Aktiv)</span>
                </h4>
              </div>
              <div class="card-body">
                <p class="mb-0">{{ theory.description }}</p>
              </div>
              <div class="card-footer">
                <button @click="addTheory(theory)" class="btn btn-block btn-primary">Diese Theorie erstellen</button>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 mb-3">
            <div class="card h-100 text-muted">
              <div class="card-header">
                <h4 class="mb-0">Eigene Theorie</h4>
              </div>
              <div class="card-body">
                <p class="mb-0">Du kannst natürlich auch dein eigenes Wissen anwenden und eine eigene Theorie erstellen.</p>
              </div>
              <div class="card-footer">
                <router-link to="/theories/add" class="btn btn-block btn-primary">Neue leere Theorie erstellen</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="row mt-3">
        <div v-for="(theory, index) in theories" class="col-md-4 mb-3" :key="index">
          <div class="card h-100" :class="activeTheoryId === theory.id ? 'border-primary' : ''">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h4 class="mb-0">
                    <i class="fad" :class="theory.icon"></i> {{ theory.title }}
                  </h4>
                </div>
                <div class="col-auto">
                  <span v-if="activeTheoryId === theory.id" @click="switchTheory('')" class="badge badge-primary clickable">
                    Aktiv
                  </span>
                  <span v-else @click="switchTheory(theory.id)" class="badge badge-dark clickable">
                    Aktivieren
                  </span>
                </div>
              </div>
            </div>
            <div class="card-body">
              <p class="mb-0">{{ theory.description }}</p>
            </div>
            <div class="card-footer">
              <router-link :to="'/theories/' + theory.id" class="btn btn-primary btn-block mr-3">Details</router-link>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 mb-3">
          <div class="card h-100 text-muted">
            <div class="card-header">
              <h4 class="mb-0">
                Neue Theorie hinzufügen
              </h4>
            </div>
            <div class="card-body">
              <p class="mb-0">Erstelle eine neue Theorie, um neue Ideen und zusätzlichen Wert zu schaffen.</p>
            </div>
            <div class="card-footer">
              <button @click="showExamples = true" class="btn btn-block btn-primary">Theorie erstellen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Theories',
  components: {
  },
  data() {
    return {
      showExamples: false,
    }
  },
  computed: {
    templateTheories() {
      return this.$store.state.templateTheories.filter(template => !this.project.theories.find(theory => theory.template === template.title));
    },
    theories() {
      return this.project.theories.map(theory => {
        theory.audits = this.project.audits.filter(audit => audit.theoryId === theory.id);
        theory.findings = this.project.findings.filter(finding => finding.theoryId === theory.id);
        theory.ideas = this.project.ideas.filter(idea => idea.theoryId === theory.id);
        theory.experiments = this.project.experiments.filter(experiment => experiment.theoryId === theory.id);
        return theory;
      });
    }
  },
  methods: {
    addTheory(theory) {
      let temp = JSON.parse(JSON.stringify(theory));

      this.project.theories.push(temp);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'theories', data: this.project.theories})
          .then(() => {
            let members = JSON.parse(JSON.stringify(this.project.members));
            members.find(member => member.email === this.user.email).settings.activeTheoryId = temp.id;

            if (members.length === 0) {
              console.warn("Error switching Theory");
            } else {
              this.$store.dispatch('project/updateProjectByProp', {prop: 'members', data: members})
                  .then(() => {
                    setTimeout(() => {
                      this.$router.push("/theories/" + temp.id);
                    }, 300);
                  })
                  .catch(err => {
                    console.error(err);
                  });
            }
          });
    },
    switchTheory(theoryId) {
      this.hideUI = true;
      let members = JSON.parse(JSON.stringify(this.project.members));
      members.find(member => member.email === this.user.email).settings.activeTheoryId = theoryId;

      if (members.length === 0) {
        console.warn("Error switching Theory");
      } else {
        this.$store.dispatch('project/updateProjectByProp', {prop: 'members', data: members })
            .then(() => {
              this.hideUI = false;
              this.$forceUpdate();
            })
            .catch(err => {
              console.error(err);
              this.hideUI = false;
              this.$forceUpdate();
            });
      }
    }
  }
}
</script>
